import React from "react"

import Layout from "../components/layout"
import ImageMeta from "../components/ImageMeta"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const pickleball = () => {
  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={"en"}>
        <SEO
          lang="en"
          title={`Pickleball Social | Hosted by Fresno Oral Maxillofacial Group`}
          description="We are excited to have the Fresno Oral Maxillofacial Group at Sierra Sport Racquet Club for a fun evening of pickleball."
        />

        <div
          style={{
            padding: "140px 40px 40px 40px",
            maxWidth: "600px",
            margin: "0 auto",
          }}
        >
          <ImageMeta
            cloudName="nuvolum"
            publicId="FOMS/DEV/Pickleball"
            responsive
          />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default pickleball
